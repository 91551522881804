body
{
    background: url("/assets/img/backgrounds/auth-background.jpg") no-repeat center center fixed !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    width: 100% !important;
    height: 100% !important;

    &:before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom right, #848484, #000);
        opacity: .5;
    }
}

p {
    color: $dark;
}

.navbar-actions {
    display: inline-flex;
    list-style: none;
    li {
        margin-left: 20px;
    }
}

.prev-btn {
    margin-top: 20px;
    padding-top: 20px;
    position: absolute;
    top: 23px;
    .zmdi-chevron-left {
        color: #42a5f5;
    }
    .zmdi-chevron-left:hover {
        color: #0087f5;
    }
}

.alert-success-icon {
    font-size: 24px;
}

.erfs
 {
    background: #99caff !important;
}

.a19 {
    background: #99caff;
}

.a19:hover {
    background: #42a5f5;
}

/*
Brand Logo Styling
*/
.brand-logo-dark
{
    font-size: 50px;
    font-weight: 200;
    line-height: 15px;
    padding: 17px 15px 13px;
    color: #777 !important;
    font-family: 'Open Sans', sans-serif;
}

.brand-logo-dark span
{
    color: $blue !important;
}

.brand-logo-light
{
    font-size: 30px;
    font-weight: 200;
    line-height: 15px;
    padding: 17px 15px 13px;
    color: #fff !important;
    font-family: 'Open Sans', sans-serif;
}

.brand-logo-light span
{
    color: $blue !important;
}

.jve-logo
{
    border-radius: 0 !important;
}

.navbar-logo
{
    margin-top: 7px;
}

.navbar-logo a strong
{
    font-size: 30px !important;
}

/*
    Size styling
*/
.full-width
{
    width: 100% !important;
}

.full-height
{
    height: 100% !important;
}

.m-r-3
{
    margin-right: 3px !important;
}

.m-t-20
{
    margin-top: 20px !important;
}

.m-t-25
{
    margin-top: 25px !important;
}

.m-b-35
{
    margin-bottom: 35px !important;
}

.p-r-0
{
    padding-right: 0px !important;
}

.p-r-5 {
    padding-right: 5px !important;
}

.p-r-10
{
    padding-right: 10px !important;
}

.p-r-15
{
    padding-right: 15px !important;
}

.p-r-20
{
    padding-right: 20px !important;
}

.p-r-25
{
    padding-right: 25px !important;
}

.p-r-30
{
    padding-right: 30px !important;
}

/*
    Text styling
*/
.bigtext
{
    text-transform: uppercase;
}
.small-text
{
    font-size: 9px !important;
    line-height: 1;
    .small-text i
    {
        font-size: 9px !important;
    }
}

.medium-text
{
    font-size: 12px !important;
}

.large-text
{
    font-size: 15px !important;
}

.small-icon
{
    font-size: 14px !important;
}

.medium-icon
{
    font-size: 22px !important;
}

.large-icon
{
    font-size: 42px !important;
}

.font-700
{
    font-weight: 700;
}
.dark-text
{
    color: $dark !important;
}

.light-text
{
    color: $light !important;
}

.landing-page-text
{
    font-size: 18px;
    color: $dark;
}

.landing-page-enrol-text
{
    font-weight: 700;
}

.remember-me-text
{
    position: relative;
    bottom: 2px;
}

.forgot-pass
{
    color: $lighter-dark !important;
    font-weight: 700;
}

.panel-group .panel .panel-title>a.upload-panel-header-text
{
    color: #fff !important;
    border-radius: 4px !important;
}

.link-text {
    color: $darker-blue;
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
}

.text-right {
    text-align: right !important;
}

/*
    Button styling
*/
.shadow-hov:hover
{
    box-shadow: 0 14px 26px -12px rgba(255, 255, 255, 0.7),
                0 4px 23px 0 rgba(0, 0, 0, 0.7),
                0 8px 10px -5px rgba(255, 255, 255, 0.7)
}

.card-actions.search-icon>li>a:before {
    background-color: rgba(0, 0, 0, 0) !important;
}

.btn-jve
{
    background: $blue !important;
    box-shadow: none !important;
}

.erf-btn
{
    width: 100%;
    padding: 5px 10px;
    border-radius: 4px;
    background: $blue !important;
    margin-bottom: 10px;
    .erf-btn:active
    {
        background: $darker-blue !important;
    }
}

.btn-property-toolbar
{
    border: 2px solid;
    border-radius: 4px;
    margin-top: 6px;
}

body#auth_wrapper #login_wrapper #login_content
{
    padding: 70px 30px 30px;
    width: 800px;
}

#content_outer_wrapper #content_wrapper.card-overlay #header_wrapper
{
    background: transparent !important;
}

#content_outer_wrapper #content_wrapper.card-overlay #header_wrapper.header-lg+#content
{
    padding-top: 75px;
}

#header_wrapper h1
{
    color: $lighter-dark !important;
}

#footer_wrapper, body #app_wrapper #content_wrapper #header_wrapper
{
    background: $blue !important;
}

#content_outer_wrapper
{
    padding-left: 0px !important;
}

#content .nav-pills>li>a
{
    background: $blue ;
    color: #fff !important;
}

#content .nav-pills>li
{
    padding: 0px 0px 12px 0px;
}

#content .nav-pills>li.active>a, #content .nav-pills>li.active>a:focus, #content .nav-pills>li.active>a:hover
{
    background: $darker-blue !important;
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
}

ul.card-actions.buttons.right-top
{
    right: 5px;
}

ul.card-actions.icons.right-top
{
    right: 0px;
    top: 4px;
}

.card .card-search .form-group
{
    height: 35px !important;
}

.card .card-search .close-search
{
    right: 0px !important;
}

.card .card-search .form-control
{
    text-indent: 15px !important;
}

.card .card-heading
{
    border-radius: 4px;
}

.rounded-border
{
    border-radius: 4px;
}

.card-dark-blue
{
    background: $darker-blue !important;
}

.dropdownjs>ul>li:active, .dropdownjs>ul>li:focus, .dropdownjs>ul>li:hover, .icon a:hover, .loader.loader-pink:before, .progress-bar
{
    background-color: $blue !important;
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
}

#app_topnavbar-wrapper .navbar-nav.left-menu
{
    padding-left: 25px !important;
}

.panel-group .panel-default>.upload-panel
{
    background-color: $blue !important;
}

.panel-group .panel
{
    border-bottom: none;
    border: none;
    border-radius: 4px;
}

.upload-fileinput
{
    margin: 5px 0 0;
}

.upload-btn
{
    padding: 0 12px 0 0 !important;
}

.jve-toolbar-text {
    padding: 21px 15px 0px !important;
}
.jve-toolbar-buttons {
    margin-top: 2px !important;
    padding-right: 3px !important;
}

.property-type {
    display: table-cell !important;
}

.a-19-form-tab-icons{
    display: none;
}

.soil-table, .soil-th, .soil-td {
    border: 1px solid #d7e1ed;
}

.section-divider {
    border: 1px solid #d1d9e4 !important;
    margin-top: 60px;
}

.pre-registration-collapse {
    background-color: $blue !important;
}

.input-block {
    border: 1px solid #eee !important;
    width: 50% !important;
    margin: 10px auto 0 !important;
    background-image: none !important;
}

.appendix-b3-blocks {
    width: 95% !important;
}

a.sign-out-text:hover {
    color: $blue !important
}

.light-border-bottom {
    border-bottom: 1px solid #ddd;
}

.form-scroll-nav {
    width: 140px;
}

.list-roman {
    list-style: lower-roman !important;
}

.erf-delete {
    padding: 3px 6px;
    cursor: pointer;
    position: relative;
    top: 30px;
    left: 8px;
    z-index: 999;
    color: #fff;
}

.a19-delete {
    padding: 3px 6px;
    cursor: pointer;
    position: relative;
    top: 42px;
    left: 8px;
    z-index: 999;
    color: #fff;
}

.search-bar-heading {
    border: 1px solid;
    padding: 1px;
}

.erf-list {
    max-height: 555px;
    margin-bottom: 10px;
    overflow:scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.erf-nav {
    padding: 0px !important;
    margin-right: 10px;
}

.highlight-changes {
    color: red !important;
    border-bottom: 1px solid red;
}

.form-control[disabled], .form-group .form-control[disabled], fieldset[disabled] .form-control, fieldset[disabled] .form-group .form-control {
    color: #555 !important;
    cursor: default !important;
    border-bottom: 1px solid #ddd !important;
}

.checkbox-inline.disabled, .checkbox.disabled label, .radio-inline.disabled, .radio.disabled label, fieldset[disabled] .checkbox label, fieldset[disabled] .checkbox-inline, fieldset[disabled] .radio label, fieldset[disabled] .radio-inline, fieldset[disabled] input[type=checkbox], fieldset[disabled] input[type=radio], input[type=checkbox].disabled, input[type=checkbox][disabled], input[type=radio].disabled, input[type=radio][disabled]{
    cursor: inherit !important;
}

.radio input[type=radio][disabled]~.check, label.radio-inline input[type=radio][disabled]~.check {
    background-color: #42a5f5 !important;
    opacity: 1;
}

/*
SCROLL BAR STYLING
*/
/* width */
.erf-list::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.erf-list::-webkit-scrollbar-track {
    box-shadow: transparent;
    border-radius: 2px;
}

/* Handle */
.erf-list::-webkit-scrollbar-thumb {
    background: $blue;
    border-radius: 2px;
}

/* Handle on hover */
.erf-list::-webkit-scrollbar-thumb:hover {
    background: $darker-blue;
}

label.business-type {
 padding:20px;
 display:block;
}

label.business-type .circle {
    margin-top: 10px;
}

label.business-type input[type=radio]:checked~.check {
    margin-top: 10px;
}

label.business-type:hover {
 background:#eee;
 cursor:pointer;
}

.submitLoader {
    display: initial !important;
}

.preloader {
    display: block !important;
}

.preloader.pls-blue .plc-path {
    stroke: #fff !important;
}

.plc-path {
    stroke-width: 4px !important;
}

.showloader {
    width: 130px;
}

.showloaderbtn {
    padding: 22px 0 !important;
    margin-bottom: 10px !important;
}

.loaderbtn {
    margin-bottom: 11px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
}

.mdl-button--raised.mdl-button--colored {
    background: #42a5f5 !important;
}

.mdl-button--raised {
    box-shadow: 0 12px 20px -10px rgb(66, 165, 245), 0 5px 20px 0 rgba(0,0,0,.14), 0 5px 19px -7px rgb(66, 165, 245) !important;
}

.scroller_anchor {
    height: 100px;
    margin:0;
    padding:0;
}

.pointer {
    cursor: pointer;
}

#content_wrapper.open-left-lg .form-drawer {
    left: 0px;
}

.drawer-left-lg .card.profile-menu .card-body .form-drawer-items ul li:hover {
    background: transparent !important;
}


.page-title{
    background: #e7e7e7 none repeat scroll 0 0;
    border: 1px solid #c1c1c1;
    font-weight: 100;
    margin: 0;
    padding: 15px 0;
    text-transform: uppercase;
}

.widget {
    background: #ececec none repeat scroll 0 0;
    border: 1px solid #adadad;
    border-radius: 5px 5px 0 0;
    margin: 20px 0;
    padding: 0;
}
.widget-title {
    background: #e4e4e4 none repeat scroll 0 0;
    border-radius: 5px 5px 0 0;
    font-size: 22px;
    font-weight: 100;
    margin: 0;
    padding: 15px 0;
    text-transform: uppercase;
}
.add-remove-class{
    margin:15px 0px;
}

.show-more-snippet {
    height:35px;
    width:100%;
    overflow:hidden;
}

#div-id{
    display:none;
}

.content_wrapper_text span{
    margin: 15px 0 0 0 ;
}

.v-mid {
    vertical-align: middle !important;
}

.border-none {
    border: none !important;
}
