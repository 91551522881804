/* RESPONSIVE */
@media (max-width:1500px) {
    .preview-form-text {
        display: none;
    }
}
@media (max-width:1300px) {
    .jve-toolbar-text {
        font-size: 1em !important;
    }
}
@media (max-width:1200px) {
    .toolbar-form-button {
        display: none;
    }
    .xs-p-60 {
        padding: 60px;
    }
}
@media (max-width:991px) {
    .toolbar-form-button {
        display: inherit;
    }
    #navigation {
        display: none;
    }
    #login_content {
        width: inherit !important;
    }
    body#auth_wrapper #login_wrapper {
        width: inherit !important;
    }
}
@media(max-width: 800px){
    body#auth_wrapper #login_wrapper #login_content
    {
        width: 375px;
    }
    body#auth_wrapper #login_wrapper {
        width: 375px !important;
    }
}
@media (max-width: 767px){
    .toolbar-form-button {
        display: none;
    }
    .soil-modal {
        width: inherit !important;
    }
    .a-19-form-tab-icons {
        display: inherit;
    }
    .a-19-form-tab-text {
        display: none;
    }
    .a-19-form-inline-input {
        display: inline-table;
    }
}
@media (max-width: 550px){
    .jve-toolbar-buttons {
        position: inherit !important;
        text-align: center;
        margin: 20px auto 0;
        padding-top: 15px !important;
    }
    .jve-toolbar-text {
        text-align: center;
        font-size: 20px !important;
    }
    .property-type {
            display: inline !important;
    }
}
