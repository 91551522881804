body#auth_wrapper
{
    background: url("/assets/img/backgrounds/auth-background.jpg") no-repeat center center fixed;
}
#auth_wrapper a:link:not(.btn),
#auth_wrapper a:visited:not(.btn)
{
    color: #fff;
}
body#auth_wrapper #login_wrapper .logo
{
    width: 100%;
    height: auto;
    box-shadow: none;
    top: 0px;
    left: 0%;
    background: #fff;
}

.help-block
{
    color: #F00;
}

body#auth_wrapper #login_wrapper {
    width: 800px !important;
}
