/*
    Default Variables
*/
@import "_variables";

/*
    Default Styling
*/
@import "css/default";

/*
    Auth Styling
*/
@import "css/auth";

/*
    Dashboard Styling
*/
@import "css/dashboard";

/*
    Responsive Styling
*/
@import "css/responsive";
